<template>
  <div>
    <v-header 
      class="py-24"
      title="Page not found."
      subtitle="ERROR 404"
      description="The page you are looking for does not appear to exist"
    />
  </div>
</template>

<script>
import header from '@/components/general/header.vue';

export default {
    components : {
        'v-header' : header,
    }
};
</script>

<style>

</style>