<template>
  <div class="bg-white border-b border-gray-300 md:pt-16 md:pb-32">
    <div class="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
      <div>
        <h2 class="text-base font-semibold tracking-wide uppercase text-primary-600">
          {{ subtitle }}
        </h2>
        <p class="mt-2 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          {{ title }}
        </p>
        <p class="mt-5 text-xl text-gray-500 ">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props : {
        subtitle : {
            type : String,
            required : true,
        },
        title : {
            type : String,
            required : false,
            default : ''
        },
        description : {
            type : String,
            required : true,
        },
    }
};
</script>

<style>

</style>